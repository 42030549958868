import React, { useEffect } from "react"
import "./animated-background-dot.css"
import gsap from "gsap"
const Dots = ({ amount = 12, className = "half-bg-dec-item" }) => {
  let dots = []

  for (let i = 0; i < amount; ++i) {
    dots.push(<div className={className} key={i}></div>)
  }

  useEffect(
    dots => {
      gsap.timeline().to(".half-bg-dec-item", {
        scale: 0.1,
        repeat: -1,
        yoyo: true,
        ease: "power1.inOut",
        delay: 0.2,
        stagger: {
          amount: 5,
          grid: "auto",
          from: "top",
        },
      })
    },
    [dots]
  )

  return dots
}

const Dot = ({ time = 1000, key = key, className = "half-bg-dec-item" }) => {
  useEffect(() => {
    const interval = setInterval(() => {}, time)
    return () => {
      ;<div className={className} key={key}></div>
      clearInterval(interval)
    }
  }, [])

  return dot
}

export default Dots
